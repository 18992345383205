// ----------------------------------------------------------------------------/
// TIM Group acquired by Mergermarket Group
// ----------------------------------------------------------------------------/

body.page-id-1639 {

	// Header / Page Title
	section.page-title {
		background: #17297B url('http://timgroup.com/wp-content/uploads/2017/05/hero-banner.jpg') no-repeat center / cover;

		@include breakpoint($media-sm-and-up) {
			padding: 100px 0 110px;

			h1, h2 {
				max-width: 70%; 
			}

			h1 {
				font-size: 65px;
				font-weight: 600;
				line-height: 120%;
	    		margin-bottom: 35px;
			}

			h2 {
			    font-size: 28px;
			    font-weight: 300;
			}
		}
		
		@include breakpoint($media-sm) {
			padding: 100px 0 110px;
		}

		.container {
			&::after {
				background: transparent url('http://timgroup.com/wp-content/uploads/2017/05/Mergermarket-Group-and-TIM-Group-Logos.png') no-repeat center / 500px 64px;
				content: "";
				display: inline-block;
				height: 64px;
				margin: 30px 0 0;
				width: 500px;
				@include breakpoint($media-sm) {
					background-size: 250px 32px;
					height: 32px;
					margin-top: 10px;
					width: 250px;
				}
			}
		}
	}

	// Content Areas
	.content-area .content-area__wrapper {
		margin-bottom: 0;
	}
	.featured-media {
		vertical-align: none;
	}
}


// END OF DOCUMENT
