// ----------------------------------------------------------------------------/
// Lists
// ----------------------------------------------------------------------------/

ul, ol {
    margin: 30px 0 32px;
    padding: 0;
    li {
        font-size: 19px;
        margin: 0 0 20px 40px;
        padding: 0 0 0 15px;
    }
}

// END OF DOCUMENT
