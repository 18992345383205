// ----------------------------------------------------------------------------/
// Navigation
// ----------------------------------------------------------------------------/

header nav.header__nav {
    @include span(4);
    padding: 0;

    //**
    //* Menu trigger
    //**
    .header__nav__trigger--open {
    	cursor: pointer;
    	height: 30px;
    	margin: 32px 0 0;
    	overflow: visible;
    	position: relative;
        text-align: left;
    	width: 100px;

    	span, span:before, span:after {
    		background: #2B333B;
    		display: block;
    		height: 1px;
    		opacity: 1;
    		position: absolute;
    		transition: all .3s ease-in-out;

            @include breakpoint($media-md) { height: 2px; }
    	}
    	span:before, span:after { content: ''; }

    	span {
    		left: 3px;
    		top: 6px;
    		&,
    		&:before,
    		&:after {
    			width: 22px;
    		}
    		&:before { top: 8px; width: 17px; }
    		&:after { top: 16px; transition: all .25s ease-in-out; }
    	}

	    /* Burger animation
    	&.open {
        	span, span:before, span:after { background: #fff; }
            span {
        		left: 1px;
        		transform: rotate(-45deg);
        		top: 13px;
        		width: 26px;
        		&:before {
        			top: 0px;
        			transform: rotate(90deg);
        			width: 26px;
        		}
        		&:after {
        			opacity: 0;
        			top: 0px;
        			transform: rotate(45deg);
        			width: 0;
        		}
            }
    	}*/

        // Label
        label {
            @extend .cta-text;
            cursor: pointer;
            display: inline-block;
            margin: 1px 0 0 40px;
            vertical-align: middle;

            @include breakpoint($media-xs) { display: none; }
        }
    }

    //**
    //* Menu drawer
    //**
    aside.header__nav__menu {
        @include bleed-x(100% 0);
        background: #2B333B;
        color: #fff;
        height: 100vh;
        margin-left: -140%;
        padding-right: 40px;
        padding-top: 30px;
        padding-bottom: 0;
        position: fixed;
        top: 0;
        z-index: 9999999;
        transition: margin-left 0.5s ease-in-out;

        @include breakpoint($media-md) {
            margin-left: -200%;
        }

        &.open { margin-left: -100%; }

        .header__nav__trigger--close {
            background: transparent url('../images/navigation/nav-close.svg') no-repeat center;
            cursor: pointer;
            display: block;
            height: 45px;
            position: absolute;
            right: 28px;
            top: 27px;
            width: 40px;
            z-index: 999999;
        }

        /* Old icons
        span {
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            height: 24px;
            margin: 0 18px 2px 0;
            opacity: 0.5;
            transition: opacity .3s ease-in-out;
            vertical-align: middle;
            width: 24px;
        }*/

        // Main navigation
        ul.header__nav__menu--primary {
            list-style: none;
            margin: 0 0 26px;
            padding: 0;

            li {
                @extend .cta-text;
                font-size: 13px;
                margin: 0;
                padding: 0;
                vertical-align: middle;

                a {
                    display: block;
                    padding: 6px 0 6px;
                    &, &:active, &:focus, &:hover, &:link, &:visited {
                        color: #fff;
                        text-decoration: none;
                    }
                    &:active, &:focus, &:hover { color: $colour--primary; }
                }

                // Sub list
                > ul.sub-menu {
                    list-style: none;
                    margin: 0;
                    padding-left: 42px;
                    li {
                        background: url('../images/navigation/nav-sub-item-arrow.svg') no-repeat center right 8px / 7px;
                        font-size: 13px;
                        font-weight: 400;
                		transition: background .3s ease-in-out;
                        &:last-of-type a { padding-bottom: 16px; }
                        &:hover { background-position: center right 0; }
                    }
                }
            }

            // Icons
            > li {
                > a, a:active, > a:focus, > a:link, > a:hover, > a:visited {
                    &::before {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 24px;
                        content: "";
                        display: inline-block;
                        height: 24px;
                        margin: 0 18px 2px 0;
                        opacity: 0.5;
                        transition: all .3s ease-in-out;
                        vertical-align: middle;
                        width: 24px;
                    }
                }
                &.home a::before { background-image: url('../images/nav-icons/nav-icon_home.svg'); }
                &.platform a::before { background-image: url('../images/nav-icons/nav-icon_platform.svg'); }
                &.compliance a::before { background-image: url('../images/nav-icons/nav-icon_compliance.svg'); }
                &.awards a::before { background-image: url('../images/nav-icons/nav-icon_awards.svg'); }
                &.about a::before { background-image: url('../images/nav-icons/nav-icon_about.svg'); }
                &.careers a::before { background-image: url('../images/nav-icons/nav-icon_careers.svg'); }
                &.news a::before { background-image: url('../images/nav-icons/nav-icon_news.svg'); }
                &.contact a::before { background-image: url('../images/nav-icons/nav-icon_contact.svg'); }

                &:hover {
                    > a::before { opacity: 1; }
                    &.home a::before { background-image: url('../images/nav-icons/nav-icon_home--blue.svg'); }
                    &.platform a::before { background-image: url('../images/nav-icons/nav-icon_platform--blue.svg'); }
                    &.compliance a::before { background-image: url('../images/nav-icons/nav-icon_compliance--blue.svg'); }
                    &.awards a::before { background-image: url('../images/nav-icons/nav-icon_awards--blue.svg'); }
                    &.about a::before { background-image: url('../images/nav-icons/nav-icon_about--blue.svg'); }
                    &.careers a::before { background-image: url('../images/nav-icons/nav-icon_careers--blue.svg'); }
                    &.news a::before { background-image: url('../images/nav-icons/nav-icon_news--blue.svg'); }
                    &.contact a::before { background-image: url('../images/nav-icons/nav-icon_contact--blue.svg'); }
                }
            }
            ul.sub-menu li {
                a {
                    margin: 0;
                    &::before { display: none; }
                }
            }
        }

        // Login
        .header__nav__menu--login {
            @extend .cta-text;
            border-bottom: 1px solid rgba(255,255,255,.25);
            border-top: 1px solid rgba(255,255,255,.25);
            font-size: 15px;
            margin-bottom: 72px;
	    display:none;
            a, a:active, a:focus, a:hover, a:link, a:visited {
                color: #fff;
                display: block;
                padding: 25px 0;
                text-decoration: none;
                div {
                    background: url('../images/navigation/nav-login-arrow.svg') no-repeat center right 8px / 7px;
                    display: inline-block;
                    padding-right: 30px;
                    transition: background .3s ease-in-out;
                    vertical-align: middle;
                }
            }
            a:active, a:focus, a:hover {
                color: $colour--primary;
                span { opacity: .8; }
                div { background-position: center right 0; }
            }
        }

        // Bottom navigation
        ul.header__nav__menu--bottom {
            list-style: none;
            margin: 0 0 6px;
            padding: 0;
            li {
                display: block;
                font-size: 15px;
                margin-bottom: 0;
                a, a:active, a:focus, a:hover, a:link, a:visited {
                    color: #fff;
                    display: inline-block;
                    padding: 0;
                }
                a, a:link, a:visited { opacity: .5; }
                a:active, a:focus, a:hover { opacity: 1; }
            }
        }

        // Copyright text
        p.copyright {
            color: #fff;
            font-size: 15px;
            margin-bottom: 28px;
            opacity: .5;
        }
    }
}


// END OF DOCUMENT
