// ----------------------------------------------------------------------------/
// Team
// ----------------------------------------------------------------------------/

section.team {
	.team__list {
		border-top: 1px solid $colour--support;
		clear: both;
		margin-bottom: 60px;
		padding-top: 30px;
	}

	h5 { color: $colour--support; }

	.team__list__member {
		@include span(2);
		margin-bottom: 60px;
		&:nth-of-type(6n + 0) { @include span(2 last); }

		.team__list__member__photo {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 176px;
			margin-bottom: 16px;
			text-decoration: none;
		}
		p {
			a, a:active, a:focus, a:hover, a:link, a:visited { color: $colour--secondary; }
			strong {
				color: $colour--secondary;
				display: block;
				margin-bottom: 4px;
			}
			span { @extend .text--sm; }
		}

		@include breakpoint($media-md) {
			@include span(4);
			margin-bottom: 40px;
			&:nth-of-type(6n + 0) { @include span(4); }
			&:nth-of-type(3n + 0) { @include span(4 last); }
			.team__list__member__photo { height: 225px; }
		}

		@include breakpoint($media-sm) {
			&,
			&:nth-of-type(6n + 0),
			&:nth-of-type(3n + 0) {
				@include span(6);
			}
			&:nth-of-type(2n + 0) { @include span(6 last); }
		}

		@include breakpoint($media-xs) {
			.team__list__member__photo { height: 175px; }
		}
	}
}


// END OF DOCUMENT
