// ----------------------------------------------------------------------------/
// Feature list
// ----------------------------------------------------------------------------/

section.feature-list {
	clear: both;
	display: block;
	padding-bottom: 50px;
	width: 100%;
	@include container();

	.feature-list__row {
		border-top: 1px solid $colour--secondary;
		display: block;
		clear: both;
		width: 100%;
	}

	article {
		@include span(4);
		padding-bottom: 60px;
		padding-top: 30px;
		&:nth-of-type(3n+0) { @include span(4 last); }
		p { padding-right: 20px; }
	}

	@include breakpoint($media-sm) {
		margin-top: 70px;
		.feature-list__row { border-top: 0 none; }
		article {
			&, &:nth-of-type(3n+0) {
				@include span(12);
				padding-bottom: 30px;
			}
			p { padding-right: 0; }
		}
	}
}


// END OF DOCUMENT
