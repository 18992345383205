// ----------------------------------------------------------------------------/
// Show more
// ----------------------------------------------------------------------------/

section.roles {

	h6, a:link {
		color: $colour--secondary;
	}

	.roles__list {
		border-top: 1px solid $colour--support;
		clear: both;
		margin-bottom: 60px;
		padding-top: 30px;

		.roles__list__item {
			@include span(3);
			margin-bottom: 60px;
			padding: 0 30px 0 0;
			&:nth-of-type(4n + 0) { @include span(3 last); }

			p { font-size: 16px; }

			@include breakpoint($media-md) {
				&,
				&:nth-of-type(4n + 0) {
					@include span(6);
					padding-right: 20px;
				}
				&:nth-of-type(even) { @include span(6 last); }
			}

			@include breakpoint($media-sm) {
				&,
				&:nth-of-type(4n + 0),
				&:nth-of-type(even) {
					@include span(12);
					float: none;
				}
			}
		}
	}

	.show-more {
		display: block;
		width: 100%;

		.show-more__trigger {
			cursor: pointer;
			display: inline-block;
			font-size: 16px;

			span { display: inline-block; }

			&::before,
			&::after {
				display: inline-block;
				transition: all 0.3s ease-in-out;
			}
			&::before {
				content: "+";
				width: 15px;
			}
			&::after {
				border-bottom: 1px solid #555;
				content: "Show more";
				padding-bottom: 0;
			}
			&.visible::before { content: "-"; }
			&.visible::after { content: "Show less"; }
		}

		.show-more__reveal {
			max-height: 0;
			opacity: 0;
			padding-top: 10px;
			transition: all 0.6s ease-in-out;
			visibility: hidden;

			&.visible {
				max-height: 999rem;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}


// END OF DOCUMENT
