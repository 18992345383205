// ----------------------------------------------------------------------------/
// Variables
// ----------------------------------------------------------------------------/

/**
* Susy settings (grid)
**/
$susy: (
    columns:               12,
    gutters:               .20,
    column-width:          60px,
    gutter-position:       inside-static,
    container:             1175px
);


/**
* Layouts
**/
$html--bg-colour:          #fff;
$full-height--min:         685px;          // Minimum height on block with '.full-height' class
$container--small:         750px;
$body--gutter:             32px;


/**
* Font / typography
**/
$body--font-family:        "aktiv-grotesk", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$html--font-size:          16px;
$body--text-colour:        #555555;

$font--primary:            "aktiv-grotesk";


/**
* Colours
**/
$colour--black:            #4F4F4F;
$colour--white:            #EDEDED;
$colour--primary:          #00ACF0; // Deep Sky Blue
$colour--secondary:        #1F2E85; // Saint Patrick Blue
$colour--support:          #0DA237; // North Texas Green


/**
* Opacity levels
**/
$opacity--high:            0.7;
$opacity--mid:             0.55;
$opacity--low:             0.4;


/**
* Media queries
**/
$media-query--xs:          34em;
$media-query--sm:          48em;
$media-query--md:          62em;
$media-query--lg:          75em;
$media-query--xl:          85em;

$media-xs:                 max-width $media-query--xs;
$media-sm:                 max-width $media-query--sm;
$media-md:                 max-width $media-query--md;
$media-lg:                 max-width $media-query--lg;
$media-xl:                 max-width $media-query--xl;

$media-xs-and-up:          min-width $media-query--xs;
$media-sm-and-up:          min-width $media-query--sm;
$media-md-and-up:          min-width $media-query--md;
$media-lg-and-up:          min-width $media-query--lg;
$media-xl-and-up:          min-width $media-query--xl;


/**
* Highlighting text
**/
$highlight--background:    #00AC61;
$highlight--colour:        #fff;


// END OF DOCUMENT
