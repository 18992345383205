// ----------------------------------------------------------------------------/
// Forms
// ----------------------------------------------------------------------------/

/**
* Form
**/
form, .nf-form-cont, .frm_pro_form {
    max-width: 475px;
    padding-bottom: 150px;

    /**
    * Input group
    **/
    .input-group {
        display: block;
        margin: 0 0 25px;
        > br { display: none; }
    }


    /**
    * Label
    **/
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
    }


    /**
    * Input
    **/
    input {
        border: 1px solid #929292;
        /*border-radius: 50px;*/
        border-radius: 3.125rem !important;
        display: block;
        font-size: 16px;
        font-weight: 400;
        height: 35px;
        text-align: left;
        transition: all 0.3s ease-in-out;
        outline: none;
        padding: 0 16px 3px;
        width: 100%;
    }


    /**
    * Textarea
    **/
    textarea {
        @extend input;
        border-radius: 18px;
        min-height: 160px;
        padding-top: 10px;
        padding-bottom: 20px;
        resize: none;
    }

    button.frm_button_submit {
        width: 100% !important;
        font: 0.75rem aktiv-grotesk;
        font-weight: normal;
        font-weight: 700 !important;
        letter-spacing: 1px !important;
        letter-spacing: 0.0625rem !important;
        text-transform: uppercase;
        padding: 0.5rem 1.125rem !important;
        background-color: #00acf0 !important;
        border: 1px solid #00acf0 !important;
        border: 0.0625rem solid #00acf0 !important;
        border-radius: 3.125rem !important;
        color: #fff !important;
        text-align: left !important;
        background: url(../images/buttons/btn-arrow-white--right.svg) 92% 10px no-repeat #00acf0 !important;
        transition: all .3s ease-in-out !important;
        &:hover {
            background: url(../images/buttons/btn-arrow-white--right.svg) 95% 10px no-repeat #007cba !important;
        };
    }


    /**
    * Checkboxes
    **/
    .input--checkbox {
        background-color: transparent;
        color: #929292;
        padding: 0;
        display: block;
        margin: 0 0 10px;
        overflow: hidden;
        transition: all .3s ease-in-out;
        width: 100%;
        label {
            display: block;
            font-size: 16px;
            font-weight: 400;
            float: left;
            margin: 0;
            width: 100%;
            span {
                border: 1px solid #929292;
                border-radius: 50px;
                cursor: pointer;
                display: block;
                height: 35px;
                text-align: left;
                transition: all 0.3s ease-in-out;
                padding: 3px 16px 0;
                &:hover {
                    background-color: #F8F8F9;
                    border-color: $colour--primary;
                    color: $colour--primary;
                }
                &:active, &:focus {
                    background-color: darken(#F8F8F9, 4%);
                    border-color: darken($colour--primary, 15%);
                }
            }
            input {
                display: none;
                position: absolute;
                top: -20px;
                &:checked + span {
                    border-color: $colour--primary;
                    color: $colour--primary;
                }
            }
        }
    }



    /**
    * Select
    **/
    .custom-select-wrapper {
        display: inline-block;
        position: relative;
        user-select: none;
        width: 100%;

        // Selector
        select { display: none; }

        .postform {
            display: inline-block;
            position: relative;
            width: 100%;
        }

        .input--select__trigger {
            background-color: #fff;
            border: 1px solid $colour--primary;
            border-radius: 50px;
            color: $colour--primary;
            cursor: pointer;
            display: block;
            font-size: 16px;
            font-weight: 400;
            height: 35px;
            padding: 3px 16px 0;
            position: relative;
            width: 100%;
            &:hover { border-color: darken($colour--primary, 8%); color: darken($colour--primary, 8%); }
            &:active, &:focus { border-color: darken($colour--primary, 16%); color: darken($colour--primary, 16%); }

            // Arrow down
            &:after {
                border-bottom: 1px solid $colour--primary;
                border-right: 1px solid $colour--primary;
                display: block;
                content: '';
                display: block;
                height: 10px;
                position: absolute;
                right: 19px;
                transform: rotate(45deg) translateY(-50%);
                transition: all .4s ease-in-out;
                top: 12px;
                width: 10px;
            }
        }

        .postform--disabled {
          .input--select__trigger {
            opacity: 0.5;
            cursor: auto;
          }
        }

        // Options
        .custom-options {
            background: #fff;
            border: 1px solid $colour--primary;
            border-radius: 10px;
            box-sizing: border-box;
            display: block;
            left: 0;
            margin: 10px 0;
            min-width: 100%;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 100%;
            transform: translateY(-15px);
            transition: all .4s ease-in-out;
            visibility: hidden;
            z-index: 10;
        }

        // When clicked
        .postform.opened {

            .input--select__trigger:after {
                right: 16px;
                top: 13px;
                transform: rotate(-135deg);
            }

            .custom-options {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
                visibility: visible;

                // Arrow up
                &:before {
                    background: #fff;
                    border-top: 1px solid $colour--primary;
                    border-left: 1px solid $colour--primary;
                    bottom: 100%;
                    content: '';
                    display: block;
                    height: 7px;
                    margin-bottom: -3px;
                    position: absolute;
                    right: 18px;
                    transform: rotate(45deg);
                    transition: all .4s ease-in-out;
                    width: 7px;
                }
            }
        }

        .option-hover:before {
            background: #929292;
        }

        .custom-option {
            background: transparent;
            border-bottom: 1px solid $colour--primary;
            color: $colour--secondary;
            cursor: pointer;
            display: block;
            font-size: 16px;
            font-weight: 400;
            padding: 4px 16px 6px;
            position: relative;
            transition: all .4s ease-in-out;

            &:first-of-type {
                border-radius: 4px 4px 0 0;
            }

            &:last-of-type {
                border-bottom: 0;
                border-radius: 0 0 4px 4px;
            }

            &:hover,
            &.selection {
                padding-left: 24px;
            }
        }
    }

    /**
    * Buttons
    **/
    button {
        float: right;
        outline: none;
    }
}

/**
* Alerts
**/
.wpcf7-response-output {
    background: $colour--support;
    color: #fff;
    border-radius: 5px;
    display: block;
    margin-bottom: 40px;
    padding: 6px 12px;
}
form .wpcf7-response-output { margin-top: 40px; }
.screen-reader-response,
.wpcf7-display-none { display: none; }

.sidebar--filters {
    input[type="submit"] {
        display: none;
    }
}

/**
* Form in Text Editor
**/
.content-area__wrapper form {
    padding: 0 0 10px;
}





/**
* Ninja Forms
**/
.nf-form-cont {

    // Form Title
    .nf-form-title { display: none; }

    // Before Content
    .nf-before-form-content {
        display: none;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: right;
    }

    // Errors
    .nf-field-container {
        position: relative;

        .nf-after-field {
            .nf-error-msg {
                bottom: 5px;
                font-size: 14px;
                position: absolute;
                right: 0;
                text-align: right;
            }
        }
    }

    // Fields
    input.ninja-forms-field { padding: 8px 18px; }

    // Submit Button
    input[type="button"] {
        @extend .btn;
        @extend .btn--solid;
        @extend .btn--arrow;
        display: block;
        width: 100% !important;
    }
}



// END OF DOCUMENT
