// ----------------------------------------------------------------------------/
// Text
// ----------------------------------------------------------------------------/

/**
* Headings
**/
h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	margin: 0 0 10px;
	line-height: 100%;
}

h1 {
	font-size: 47px;
	font-weight: 500;
	line-height: 140%;
	margin-bottom: 12px;
	@include breakpoint($media-xs) { font-size: 42px; }
}

h2 {
	color: $colour--secondary;
	font-size: 23px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
	@include breakpoint($media-xs) { font-size: 22px; }
}

h3 {
	color: $colour--secondary;
	font-size: 29px;
	font-weight: 300;
	line-height: 150%;
	margin-bottom: 32px;
	@include breakpoint($media-xs) { font-size: 26px; }
}

h4 {
	color: $colour--secondary;
	font-size: 30px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 35px;
}

h5 {
	color: $colour--secondary;
	font-size: 21px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
}

h6 {
	color: $body--text-colour;
	font-size: 16px;
	font-weight: 700;
	line-height: 150%;
	margin-bottom: 14px;
}


/**
* Text
**/
p {
	font-size: $html--font-size;
	line-height: 150%;
	margin: 0 0 20px;
}

.text--sm {
	color: #555555;
	display: block;
	font-size: 14px;
	line-height: 150%;
}

.cta-text {
	font: 12px $font--primary;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}


// END OF DOCUMENT
