// ----------------------------------------------------------------------------/
// Sitemap
// ----------------------------------------------------------------------------/

section.sitemap {
    margin-bottom: 100px;
    ul {
        list-style: circle;;
        margin: 0;
        padding: 0 0 0 18px;
        li {
            margin: 0 0 5px;
            > ul {
                margin: 5px 0 0 32px;
            }
        }
    }
}


// END OF DOCUMENT
