// ----------------------------------------------------------------------------/
// Absolute space fill
// ----------------------------------------------------------------------------/

.space-fill {
    background: transparent;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}


// END OF DOCUMENT
