// ----------------------------------------------------------------------------/
// Social / share icons
// ----------------------------------------------------------------------------/

.social,
.share {
    display: inline-block;

    > a {
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
        display: inline-block;
        height: 24px;
        width: 24px;

        &:not(:first-of-type) { margin-left: 8px; }

        &.facebook-icon { background-image: url('../images/social/facebook-icon.svg'); }
        &.twitter-icon { background-image: url('../images/social/twitter-icon.svg'); }
        &.linkedin-icon { background-image: url('../images/social/linkedin-icon.svg'); }
        &.email-icon { background-image: url('../images/social/email-icon.svg'); }
        &.url-icon { background-image: url('../images/social/url-icon.svg'); }
    }
}


// END OF DOCUMENT
