// ----------------------------------------------------------------------------/
// Header
// ----------------------------------------------------------------------------/

header {
    background: #fff;
    box-shadow: 0 1px 15px 0 rgba(0,0,0,0.1);
    display: block;
    height: 95px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    &:before {
        @extend .space-fill;
        background: rgba(0,0,0,0.6);
        content: "";
        opacity: 0;
        position: fixed;
        transition: all 0.4s ease-in-out;
        visibility: hidden;
        z-index: -10;
    }

    &.nav--active {
        &::before {
            cursor: pointer;
            opacity: 1;
            visibility: visible;
            z-index: 99;
        }
    }

    // Logo
    .header__logo {
        @include span(4);
        text-align: center;
        img {
            margin: 14px auto 0;
            max-width: 180px;
        }
    }

    // CTA Buttons
    .header__buttons {
        @include span(4 last);
        padding: 29px 0 0;
        text-align: right;

        > a.btn {
            margin-left: 10px;

            @include breakpoint($media-xs) {
                background: transparent !important;
                border: 0 none;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @include breakpoint($media-lg) {
        .header__buttons > a:not([id^="login"]) { display: none; }
    }

    @include breakpoint($media-md) {
        .header__logo img {
            margin-top: 20px;
            max-width: 140px;
        }
    }
}


// END OF DOCUMENT
