// ----------------------------------------------------------------------------/
// Sidebar
// ----------------------------------------------------------------------------/

aside.sidebar {
	@include span(3);
	clear: none;
	padding-bottom: 70px;
    padding-left: 0;
    padding-right: 0;

	~ .content-area {
		@include span(9 last);
		clear: none;
		padding: 0;
	}

	.sidebar__back-btn {
		vertical-align: top;
		> a.btn.btn--outline {
			background: url('../images/buttons/btn-arrow-back.svg') no-repeat center left 20px;
			&:hover { background-position: center left 12px; }
		}
	}

	@include breakpoint($media-md) {
		&,
		~ .content-area { @include span(12); }

		.sidebar__back-btn { display: inline-block; }
	}
}


// END OF DOCUMENT
