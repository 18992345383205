// ----------------------------------------------------------------------------/
// Vertical centre
// ----------------------------------------------------------------------------/

.vertical-centre {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


// END OF DOCUMENT
