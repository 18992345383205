// ----------------------------------------------------------------------------/
// Media collection
// ----------------------------------------------------------------------------/

section.media-collection {
	clear: both;
	display: block;
	padding: 10px 0 120px;
	width: 100%;

	.featured-media { margin-bottom: 35px; }

	// Two
	&.two-column .featured-media {
		@include span(6);
		&:nth-of-type(2n+0) { @include span(6 last); }
	}

	// Three
	&.three-column .featured-media {
		@include span(4);
		&:nth-of-type(3n+0) { @include span(4 last); }

		@include breakpoint($media-md) {
			@include span(6);
			&:nth-of-type(2n+0) { @include span(6 last); }
			&:nth-of-type(3n+0) { @include span(6); }
		}
	}

	// Four
	&.four-column .featured-media {
		@include span(3);
		&:nth-of-type(4n+0) { @include span(3 last); }

		@include breakpoint($media-md) {
			@include span(6);
			&:nth-of-type(2n+0) { @include span(6 last); }
		}
	}

	// ALL Mobile
	@include breakpoint($media-sm) {
		&.two-column .featured-media,
		&.three-column .featured-media,
		&.four-column .featured-media {
			&,
			&:nth-of-type(2n+0),
			&:nth-of-type(3n+0),
			&:nth-of-type(4n+0) {
				@include span(12);
				float: none;
				width: 100%;
			}
		}
	}
}


// END OF DOCUMENT
