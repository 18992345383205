// ----------------------------------------------------------------------------/
// Article single
// ----------------------------------------------------------------------------/

article.single {
    padding-bottom: 140px;

    .single__header {
        border-bottom: 1px solid #555;
        margin-bottom: 32px;
        padding-bottom: 12px;

        // Category / label
        label {
            color: $colour--support;
            display: block;
            font: 12px $font--primary;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        // Title
        h1 {
            color: $colour--secondary;
            font-weight: 300;
            margin-bottom: 20px;
        }

        // Publish date
        span.single__date {
            display: block;
            text-align: right;
            width: 100%;
        }
    }

    // Content
    .single__content {

        h2, h3, h4, h5 {
            margin-top: 80px;
        }

        img {
            margin: 70px auto;
            &.alignright {
                float: right;
                margin-left: 30px
            }
            &.alignleft {
                float: left;
                margin-right: 30px
            }

    		@include breakpoint($media-sm) {
    			&.alignright,
                &.alignleft {
                    float: none;
                    margin-left: 0;
                    margin-right: 0;
                }
    		}
        }
    }
}


// END OF DOCUMENT
