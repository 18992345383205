// ----------------------------------------------------------------------------/
// Blocks & sections
// ----------------------------------------------------------------------------/
// INDEX:
// 01   Setup

//  NOTES (@devs):
//  Please ensure naming conventions are using BEM methodology (Block, Element,
//  Modifier) to ensure consistency.


// 01
/* ----------------------------------/
/* Setup
/* ---------------------------------*/
section {
	clear: both;
	display: block;
    //overflow: hidden;
    position: relative;
    width: 100%;
}



// END OF DOCUMENT
