// ----------------------------------------------------------------------------/
// Contact sidebar
// ----------------------------------------------------------------------------/

aside.sidebar.contact-information {
	@include span(4);
    padding-left: 0;
    padding-right: 0;

	~ .content-area {
		@include span(8 last);
		padding: 0;
	}

	h6 {
		color: $colour--secondary;
	}
	p {
		color: #929292;
		font-size: 16px;
	}
	a, a:active, a:focus, a:hover, a:link, a:visited { color: $colour--secondary; }

	.sidebar__telephone {
		margin-bottom: 50px;
		p {
			margin-bottom: 0;
			span {
				display: inline-block;
				width: 80px;
			}
		}
	}

	.sidebar__email {
		> div {
			margin-bottom: 25px;
			p {
				margin-bottom: 5px;
				line-height: 120%;
				label { font-weight: 700; }
			}
		}
	}
}


// END OF DOCUMENT
