// ----------------------------------------------------------------------------/
// Page title
// ----------------------------------------------------------------------------/

section.page-title {
	background: $colour--secondary;
	color: #fff;
	margin-bottom: 60px;
	min-height: 180px;
	padding: 24px 0 5px;

	@include breakpoint($media-md) {
		margin-bottom: 40px;
	}

	h2 {
		color: #fff;
		opacity: .8;
	}
}


// END OF DOCUMENT
