// ----------------------------------------------------------------------------/
// Tabbed content
// ----------------------------------------------------------------------------/

section.tabbed-content {
	margin-top: -95px;

	@include breakpoint($media-sm) { margin-top: -60px; }

	.tabs {
		display: flex;
		flex-wrap: wrap;

		// Fixed (on scroll)
		&.fixed {
			dt {
				position: fixed;
				top: 95px;
			}
		}

		// Tab
		dt {
			@extend .cta-text;
			width: 33.3333%;
			background: #797F8A;
			box-shadow: inset 0 -6px 8px 0 rgba(0,0,0,0.15);
			color: #fff;
			cursor: hand;
			cursor: pointer;
			display: inline-block;
			flex-grow: 1;
			font-size: 20px;
			font-weight: 400;
			height: 95px;
			padding: 35px 20px 0;
			text-align: center;
			transition: all .3s ease-in-out;
			vertical-align: top;

			&:hover {
				background-color: #797F8A;
				box-shadow: inset 0 -3px 4px 0 rgba(0,0,0,0.1);
			}

			@include breakpoint($media-sm) {
				font-size: $html--font-size;
				height: 60px;
				padding: 20px 8px 0;
			}
			@include breakpoint($media-xs) {
				font-size: 15px;
				padding-top: 21px;
			}

			&.tab-style-2 {
			  background: #555A62;
			}
		}

		// Content
		dd {
			display: none;
			flex: 1 1 100%;
			order: 2;
			padding: 65px 0 0;

			@include breakpoint($media-sm) { padding: 30px 0 70px; }

			.content-area__wrapper { max-width: 785px; }

			.btn {
				display: inline-block;
				margin: 10px 0;
			}

			img {
				height: auto;
				max-width: 100%;
			}
		}

		// Active tab
		dt.active,
		dt.active:hover {
		    background-color: #fff;
			box-shadow: none;
			color: $colour--primary;
		    cursor: default;
			font-weight: 700;

			+ dd {
				display: block;
			}
		}
	}
}

.sticky-tabs {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: black;
}

.sticky-tabs__inner {
	display: block;
	@extend .container;
	margin-top: -1000px !important;
	overflow: hidden;

	&.sticky-tabs__inner--showing {
		margin-top: 0 !important;
	}
}

.sticky-tab {
	display: block;
	float: left;
	width: 33.3333%;
	padding: 35px $body--gutter * 0.5;
	background-color: #797F8A;
	border: none;
	outline: 0;
	font-size: 20px;
	line-height: 20px;
	text-transform: uppercase;
	color: $colour--white;
	transition: padding 0.2s, background-color 0.5s, color 0.5s;

	&:hover {
		background-color: #797f8a;
	}

	@include breakpoint($media-sm) {
		padding: 20px $body--gutter * 0.25;
	font-size: 18px;
	}

	@include breakpoint($media-xs) {
		font-size: 15px;
	}

	&.sticky-tab--active {
		background-color: white !important;
		color: $colour--primary;
		font-weight: bold;
	}

	&.tab-style-2 {
	  background-color: #555A62;
	}
}

.sticky-tabs__inner.sticky-tabs__inner--narrow {
	.sticky-tab {
		padding: $body--gutter * 0.5;

		@include breakpoint($media-sm) {
			padding: $body--gutter * 0.25;
		}
	}
}


// END OF DOCUMENT
