// ----------------------------------------------------------------------------/
// Hero
// ----------------------------------------------------------------------------/

section.hero {
	background-position: center;
	background-size: cover;
	color: #fff;
	height: calc(100vh - 95px);
	min-height: 490px;

	.container {
		@extend .vertical-centre;
		height: calc(100vh - 520px);
		padding-top: 120px;
		position: static;
		width: 100%;

		h1 {
			max-width: 420px;
			position: absolute;
			width: calc(100% - #{$body--gutter});
			z-index: 2;
		}

		// Shadow (overlay fade)
		&:after {
			background: url('../images/hero/hero-gradient.png') repeat-y center left;
			content: "";
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

            @include breakpoint($media-xs) { opacity: 0.4; }
		}
	}
}


// END OF DOCUMENT
