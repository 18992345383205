// ----------------------------------------------------------------------------/
// Main setup
// ----------------------------------------------------------------------------/

*, *::after, *::before {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body { height: 100%; }

html {
    background: $html--bg-colour;
    font-size: $html--font-size;
    body {
        background: $html--bg-colour;
        color: $body--text-colour;
        font: 1rem/1.6rem $body--font-family;
        font-weight: 400;
        letter-spacing: 0rem;
        margin: 0 auto;
        position: relative;
        width: 100%;
        /* Font smoothing */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        // Main
        main {
            min-height: calc(100% - 516px);
            padding-top: 95px;
			transition: height 0.3s ease-in-out;

            > .container:first-of-type {
                @include container;
                vertical-align: top;
            }
        }

        /* Container */
        .container {
            margin: 0 auto;
            max-width: container();
            position: relative;
    		@include breakpoint($media-lg) { padding: 0 $body--gutter; }
    		@include breakpoint($media-md) { padding: 0 $body--gutter / 2; }
        }
    }
}

/**
* Highlighting text
**/
::-moz-selection {
    background: $highlight--background;
    color: $highlight--colour !important;
    text-shadow: none;
}
::selection {
    background: $highlight--background;
    color: $highlight--colour !important;
    text-shadow: none;
}

/**
* Cookie policy
**/
.cc_container {
    padding-bottom: 65px !important;
    .cc_btn { bottom: 15px !important; }
    .cc_logo { display: none !important; }
}



// END OF DOCUMENT
