// ----------------------------------------------------------------------------/
// Article snippet
// ----------------------------------------------------------------------------/

section.article-list {
	margin: 50px 0;
	article.snippet {
		@include span(4);
		margin-bottom: 32px;
		&:nth-of-type(3n + 0) { @include span(4 last); }

		@include breakpoint($media-md) {
			@include span(6);
			&:nth-of-type(2n + 0) { @include span(6 last); }
			&:nth-of-type(3n + 0) { @include span(6); }
		}

		@include breakpoint($media-sm) {
			&,
			&:nth-of-type(2n + 0) { @include span(12); }
			&:nth-of-type(3n + 0) { @include span(12); }
		}
	}

	// Load more button
	.load-more {
		clear: both;
		display: block;
		margin: 40px auto 100px;
		text-align: center;
		width: 100%;
		#loadMorePostsBtn {
			cursor: pointer;
			display: inline-block;
			&.disabled {
				cursor: auto;
				opacity: 0.3;
			}
		}
	}
}

article.snippet {
	//display: none;
	.article--snippet__image {
		background: #929292;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		height: 160px;
		margin-bottom: 10px;
		text-decoration: none;
		width: 100%;
	}
	.article--snippet__text {
		min-height: 200px;
		label {
			color: $colour--support;
			display: block;
			font: 12px $font--primary;
			font-weight: 700;
			letter-spacing: 1px;
			margin-bottom: 8px;
			text-transform: uppercase;
		}
		a, a h6 { color: $colour--secondary; }
		p { @extend .text--sm; }
	}

	&.snippet--vacancy {
		.article--snippet__text {
			span.location {
				color: #a2a2a2;
				display: block;
				font-size: 16px;
    			margin: -10px 0 10px;
			}
			label {
				color: $body--text-colour;
				margin-bottom: 2px;
			}
			a[href^="mailto:"] {
				font-size: 16px;
			}
		}
	}
}


// END OF DOCUMENT
