// ----------------------------------------------------------------------------/
// Featured media
// ----------------------------------------------------------------------------/

.featured-media {
	display: block;
	margin-bottom: 20px;
	vertical-align: top;

	.featured-media__item {
		position: relative;

		iframe, img, video {
			margin: 0 0 -6px;
			padding: 0;
			position: relative;
			width: 100%;
		}
	}

	.featured-media__label {
		background: rgba(203,203,203,.2);
		color: #2B333B;
		padding: 10px 12px 14px;

		p {
			font-size: 14px !important;
			line-height: 148%;
			margin: 0;
		}
	}
}


// END OF DOCUMENT
