// ----------------------------------------------------------------------------/
// Filters sidebar
// ----------------------------------------------------------------------------/

aside.sidebar.sidebar--filters {
	margin: 50px 0 0;

	h5, h6 {
		margin-bottom: 48px;
		padding-left: 16px;
	}

	.input-group, .btn {
	    margin: 0 0 48px;
		max-width: 200px;
		> label {
			@extend h6;
			margin: 0 0 8px;
			padding-left: 16px;
		}
	}

	form.sidebar-filter {
		margin-bottom: 0;
		padding-bottom: 0;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-bottom: 48px;
				max-width: 200px;
				h4 {
					@extend label;
					@extend h6;
					color: #555;
				    font-size: 18px;
				    font-size: 1rem;
				    font-weight: 700;
				    line-height: 150%;
					margin: 0 0 14px;
    				padding-left: 16px;
				}

				input[type="submit"] {
					@extend .btn;
					@extend .btn--solid;
					text-align: center;
				}
			}
		}
	}

	@include breakpoint($media-md) {
		margin: 25px 0 0;

		.input-group,
		.btn,
		form.sidebar-filter ul li {
			max-width: none;
		}
	}
}


// END OF DOCUMENT
