// ----------------------------------------------------------------------------/
// Tables
// ----------------------------------------------------------------------------/

table {
    border: 0 none;
    color: $body--text-colour;
    font-family: $font--primary;
    margin: 30px 0 50px;
    overflow: hidden;
    width: 100%;

    tr {
        border-bottom: 1px solid #E5E5E5;

        &:not(:first-child) { border-top: 1px solid #E5E5E5; }

        th, td { padding: 10px 8px; }
    }
}

table.awards {
    // Header
    th, td:before {
        color: $body--text-colour;
        text-transform: uppercase;
    }
    th { display: none; }


    // Rows
    tr {
        th, td {
            margin: 0;
            padding: 0;
            text-align: left;

            @include breakpoint($media-sm-and-up) {
                display: table-cell;
                padding: 10px 0 !important;

                &:first-child { padding-left: 0; }
                &:last-child { padding-right: 0; }
            }

        }

        @include breakpoint($media-sm-and-up) {
            td[data-th="Rank"] { width: 100px; }
        }
    }


    // Data
    td {
        display: block;

        &:first-child { padding-top: 10px; }
        &:last-child { padding-bottom: 10px; }

        &:before {
            content: attr(data-th)": ";
            display: inline-block;
            font-weight: 700;
            width: 110px;

            @include breakpoint($media-sm-and-up) { display: none; }
        }
    }
}


// END OF DOCUMENT
