// ----------------------------------------------------------------------------/
// Author sidebar
// ----------------------------------------------------------------------------/

aside.sidebar.sidebar--article-author {
	@include span(3);

	~ .content-area {
		@include span(7);

		@include breakpoint($media-md) {
			@include span(12);
		}
	}

	.article-author {
		margin-top: 160px;
		max-width: 230px;

		.article-author__image {
			background-color: #9C7C55;
			background-position: center;
			background-size: cover;
			border-radius: 100%;
			display: block;
			margin-bottom: 20px;
			height: 92px;
			width: 92px;
		}

		h6 {
			color: $colour--secondary;
			margin-bottom: 2px;
		}

		p {
			color: #929292;
			font-size: 16px;
			line-height: 135%;
			margin-bottom: 20px;
		}

		@include breakpoint($media-md) {
			display: inline-block;
			float: right;
			margin-top: 0;
			max-width: none;

			.article-author__image, h6 {
				display: inline-block;
				vertical-align: top;
			}
			.article-author__image {
				height: 35px;
				margin: 0 20px;
				width: 35px;
			}
			h6 {
				padding-top: 1px;
				&::before {
					content: "Published by";
					color: #929292;
					display: block;
					font-size: 14px;
					font-weight: 400;
					line-height: 100%;
					margin-bottom: 0;
				}
			}
			p { display: none; }
		}

		@include breakpoint($media-xs) {
			display: block;
			float: none;
			margin: 30px 0 0;
			.article-author__image { margin: 0; }
		}
	}

}

.postid-2070 .article-author {
	display: none;
}

// END OF DOCUMENT
