// ----------------------------------------------------------------------------/
// Images
// ----------------------------------------------------------------------------/

img {
    max-width: 100%;
    width: auto;
}


// END OF DOCUMENT
