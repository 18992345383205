// ----------------------------------------------------------------------------/
// Footer
// ----------------------------------------------------------------------------/

footer {
    clear: both;
    padding: 0;

    >.container { min-height: 314px; }

    a, a:active, a:focus, a:hover, a:link, a:visited {
        color: #fff;
    }

    // Image and request a demo button
    .footer__pre {
        background: #F8F8F9;
        padding: 70px 0 60px;

        @include breakpoint($media-lg) { padding: 40px 0; }

        img { display: inline-block; }

        .btn.btn--outline {
            border-color: $colour--primary;
            color: $colour--primary;
            display: inline-block;
            float: right;
            margin: 16px 0 0 0;
        }

        @include breakpoint($media-md) {
            text-align: center;
            img,
            .btn.btn--outline {
                float: none;
                margin: 0 auto;
                text-align: center;
            }
            img {
                display: block;
                margin-bottom: 20px;
            }
        }
    }

    // Main footer
    .footer__main {
        background: #384151;
        color: #fff;
        padding: 50px 0 0;

        @include breakpoint($media-lg) { padding: 40px 0 0; }

        // Addresses
        .addresses {
            display: inline-block;

            > p {
                display: inline-block;
                font-size: 13px;
                font-style: normal;
                line-height: 22px;
                margin-bottom: 0;
                padding-right: 16px;
                vertical-align: top;

                &:not(:first-of-type) {
                    border-left: 1px solid rgba(255,255,255,.25);
                    padding-left: 16px;
                }

                span { @extend .cta-text; }

                a::after { content: " ›"; }
            }

            @include breakpoint($media-lg) {
                address {
                    width: 45%;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 30px;
                    &:first-of-type {
                        border-left: 1px solid rgba(255,255,255,.25);
                        padding-left: 16px;
                    }
                }
            }

            @include breakpoint($media-sm) {
                border-bottom: 1px solid rgba(255,255,255,.25);
                display: block;
                margin-bottom: 40px;
                padding-bottom: 10px;
                address {
                    display: block;
                    width: 100%;
                    &:first-of-type,
                    &:not(:first-of-type) {
                        border: 0 none;
                        padding: 0;
                    }
                }
            }
        }

        // Sitemaps
        .sitemaps {
            display: inline-block;
            vertical-align: top;

    		@include breakpoint($media-lg-and-up) {
                display: block;
                float: right;
            }

            > ul {
                @extend .cta-text;
                border-left: 1px solid rgba(255,255,255,.25);
                display: inline-block;
                list-style: none;
                margin: 0;
                opacity: .6;
                padding: 0 16px;
                transition: opacity 0.3s ease-in-out;
                vertical-align: top;

                &:hover { opacity: 1;}

                li {
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    margin: 0;
                    padding: 0;
                    > ul {
                        border: 0 none;
                        display: block;
                        font-weight: 400;
                        margin: 0;
                        padding-left: 0;
                        li { line-height: 20px; }
                    }
                }

                @include breakpoint($media-sm) {
                    border: 0 none;
                    display: block;
                    padding-left: 0;
                    width: 100%;
                    &:not(:last-of-type) { margin-bottom: 30px; }
                }
            }
        }

        // Copyright area
        .bottom {
            border-top: 1px solid rgba(255,255,255,.25);
            margin-top: 50px;
            padding: 24px 0 26px;
            p {
                display: inline-block;
                font-size: 13px;
                margin-bottom: 0;
                opacity: 0.8;

                @include breakpoint($media-md) {
                    display: block;
                    margin-bottom: 20px;
                }
            }
            ul {
                display: inline-block;
                list-style: none;
                margin: 0 0 0;
                opacity: 0.8;
                padding: 0;
                li {
                    display: inline-block;
                    font-size: 13px;
                    margin-bottom: 0;
                    padding-left: 22px;
                }
            }

            @include breakpoint($media-lg) {
                ul {
                    margin-bottom: 30px;
                    li {
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }
            }

            @include breakpoint($media-sm) {
                ul {
                    display: block;
                    li {
                        padding-left: 0;
                        width: 100%;
                    }
                }
            }

            .social {
                float: right;

                @include breakpoint($media-md) {
                    display: block;
                    float: none;
                }
            }
        }
    }
}


// END OF DOCUMENT
