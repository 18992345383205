// ----------------------------------------------------------------------------/
// Full height
// ----------------------------------------------------------------------------/

.full-height {
    height: 100vh;
    min-height: $full-height--min;
}


// END OF DOCUMENT
