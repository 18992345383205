// ----------------------------------------------------------------------------/
// Career strip
// ----------------------------------------------------------------------------/

section.career-strip {
	background: #F8F8FA;
	margin: 60px 0;

	.career-strip__content {
		padding: 60px 0;
		max-width: 260px;
		width: 50%;

		p { font-size: 20px; }

		a.btn { margin-top: 10px; }
	}

	.career-strip__image {
		background-repeat: no-repeat;
		background-color: #D8D8D8;
		background-position: center;
		background-size: cover;
		bottom: 0;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 50%;
	}

	@include breakpoint($media-sm) {
		.career-strip__content {
			max-width: none;
			padding: 60px 0 80px;
			width: 100%;
		}
		.career-strip__image { display: none; }
	}
}


// END OF DOCUMENT
