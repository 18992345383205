// ----------------------------------------------------------------------------/
// Content area
// ----------------------------------------------------------------------------/

.content-area {
	display: block;
	width: 100%;

	p { font-size: 19px; }

	ul, ol {
		li {
	        font-size: 19px;
		}
	}

	.container {
		@include container;
		display: block;
		width: 100%;
		&[class*="border"] { padding: 32px 0; }
		&.border--top { border-top: 1px solid $colour--secondary; }
		&.border--bottom { border-top: 1px solid $colour--secondary; }
		&.border--vertical { border-top: 1px solid $colour--secondary; }
	}

	.vacancy-application {
		margin-top: 32px;
		a, a:active, a:link, a:focus, a:hover, a:visited {
			color: $colour--secondary;
		}
		label {
			@extend .cta-text;
			color: $colour--support;
			display: block;
		}
	}

	.content-area__wrapper {
		@include span(12);
		float: none;
		margin-bottom: 35px;
		padding: 0 0 70px;

		@include breakpoint($media-md) { margin-bottom: 0; }

		a.btn { display: inline-block; margin: 20px 0 10px; }
	}

	// Location
	.location {
		border-top: 1px solid $colour--secondary;
		display: block;
		margin-top: 60px;
		padding-top: 25px;

		h3 { display: inline-block; }
		p {
			display: inline-block;
			float: right;
			padding-top: 10px;
			text-align: right;
		}

		@include breakpoint($media-md) {
			h3 { display: block; }
			p {
				display: block;
				float: none;
				text-align: left;
			}
		}

		#map_canvas {
			background: #EAEAEA;
			height: 450px;
			width: 100%;
		}
	}

	// Content snippet
	section.content-snippet {
		border-top: 1px solid $colour--secondary;
		margin: 40px 0;
		padding: 40px 0;

		p { font-size: $html--font-size; }

		a.btn {
			display: inline-block;
			font-size: 16px;
			margin: 0 0 10px;
		}
	}

	// Grid to allow featured media
	@include breakpoint($media-md-and-up) {
		.half {
			@include span(6);
			&:nth-of-type(odd) {
				padding-left: 0;
				padding-right: 18px;
			}
			&:nth-of-type(even) {
				@include span(6 last);
				padding-left: 18px;
			}
			~ .featured-media {
				@include span(6 last);
				padding-left: 35px;
			}
		}
	}

	// Space at bottom of awards list
	&.awards-list { margin-bottom: 150px; }
}


// END OF DOCUMENT
