// ----------------------------------------------------------------------------/
// Team
// ----------------------------------------------------------------------------/

article.team__member {
	@include span(8);
	padding: 0 !important;
    margin-bottom: 140px;

	@include breakpoint($media-lg) { @include span(9 last); }
	@include breakpoint($media-md) { @include span(12); }

	.team__member__content {
		@include span(7);

		.team__member__header {
	        margin-bottom: 32px;
	        padding-bottom: 5px;

	        // Title
	        h1 {
				@extend h3;
	            color: $colour--secondary;
	            display: inline-block;
	            margin: 0 20px 20px 0;
	        }
			h6 {
				color: $body--text-colour;
				margin-bottom: 4px;
			}
			p { color: #929292; }

		    // Content
		    .team__member__bio {
				h2, h3, h4, h5 { margin-top: 80px; }
				p { font-size: 18px; }
		    }
	    }

		@include breakpoint($media-lg) { @include span(8); }
		@include breakpoint($media-sm) { @include span(12) ;}
	}

	// Photo
	.team__member__photo {
		@include span(4 last);
		img { width: 100%; }

		@include breakpoint($media-lg) { @include span(3 last); }
		@include breakpoint($media-sm) { @include span(12); margin-top: 60px; }
	}
}

// Controls
.sibling-navigate__prev,
.sibling-navigate__next {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 44px;
	height: 44px;
	overflow: hidden;
	position: absolute;
	text-indent: 100%;
	transition: background-position 0.2s ease-in-out;
	top: 125px;
	white-space: nowrap;
	width: 62px;
}

.sibling-navigate__prev { background-image: url('../images/carousel/carousel-arrow-left.svg'); }
.sibling-navigate__next { background-image: url('../images/carousel/carousel-arrow-right.svg'); }

@include breakpoint($media-lg-and-up) {
	.sibling-navigate__prev {
		background-position: center left 8px;
		left: 0;
		&:hover { background-position: center left 0; }
	}

	.sibling-navigate__next {
		background-position: center right 8px;
		right: 0;
		&:hover { background-position: center right 0; }
	}
}

@media (min-width: $media-query--md) and (max-width: $media-query--lg) {
	.sibling-navigate__prev,
	.sibling-navigate__next {
		background-size: 34px;
		height: 34px;
		left: $body--gutter;
		right: none;
		top: 85px;
		width: 34px;
	}
	.sibling-navigate__next { left: $body--gutter + 45px; }
}

@include breakpoint($media-md) {
	.sibling-navigate__prev,
	.sibling-navigate__next {
		background-size: 34px;
		height: 34px;
		left: none;
		right: $body--gutter;
		top: 0;
		width: 34px;
	}
	.sibling-navigate__prev { right: $body--gutter + 45px; }
}


// END OF DOCUMENT
