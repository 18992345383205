// ----------------------------------------------------------------------------/
// Carousel (slider)
// ----------------------------------------------------------------------------/

section.carousel {
    > .container {
        border-top: 1px solid $colour--support;
        margin-bottom: 80px;
        padding-top: 30px;
    }
    h5 { color: $colour--secondary; }

    .carousel__wrapper {
        position: relative;
    }

    // Controls
    .owl-nav {
        .owl-prev,
        .owl-next {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 44px;
            height: 44px;
            overflow: hidden;
            position: absolute;
            text-indent: 100%;
            transition: all 0.2s ease-in-out;
            top: 55px;
            white-space: nowrap;
            width: 62px;

            &.disabled {
                background-position: center !important;
                cursor: auto;
                opacity: 0.4;
            }
        }

        .owl-prev { background-image: url('../images/carousel/carousel-arrow-left.svg'); }
        .owl-next { background-image: url('../images/carousel/carousel-arrow-right.svg'); }

        @include breakpoint($media-xl-and-up) {
            .owl-prev {
                background-position: center left 8px;
                left: -75px;
                &:hover { background-position: center left 0; }
            }

            .owl-next {
                background-position: center right 8px;
                right: -75px;
                &:hover { background-position: center right 0; }
            }
        }

        @include breakpoint($media-xl) {
            .owl-prev,
            .owl-next {
                background-size: 34px;
                left: none;
                height: 34px;
                top: -62px;
                right: 0;
                width: 34px;
            }
            .owl-prev { right: 45px; }
        }
    }
}

/*
 *  Owl Carousel - Core
 */
.carousel__wrapper {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .carousel__wrapper .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .carousel__wrapper .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .carousel__wrapper .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .carousel__wrapper .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .carousel__wrapper .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d; }
  .carousel__wrapper .owl-nav.disabled,
  .carousel__wrapper .owl-dots.disabled {
    display: none; }
  .carousel__wrapper .owl-nav .owl-prev,
  .carousel__wrapper .owl-nav .owl-next,
  .carousel__wrapper .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .carousel__wrapper.owl-loaded {
    display: block; }
  .carousel__wrapper.owl-loading {
    opacity: 0;
    display: block; }
  .carousel__wrapper.owl-hidden {
    opacity: 0; }
  .carousel__wrapper.owl-refresh .owl-item {
    visibility: hidden; }
  .carousel__wrapper.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .carousel__wrapper.owl-grab {
    cursor: move;
    cursor: grab; }
  .carousel__wrapper.owl-rtl {
    direction: rtl; }
  .carousel__wrapper.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .carousel__wrapper {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.carousel__wrapper .animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.carousel__wrapper .owl-animated-in {
  z-index: 0; }

.carousel__wrapper .owl-animated-out {
  z-index: 1; }

.carousel__wrapper .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.carousel__wrapper .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.carousel__wrapper .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.carousel__wrapper .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.carousel__wrapper .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.carousel__wrapper .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
      -ms-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3); }

.carousel__wrapper .owl-video-playing .owl-video-tn,
.carousel__wrapper .owl-video-playing .owl-video-play-icon {
  display: none; }

.carousel__wrapper .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.carousel__wrapper .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }



// END OF DOCUMENT
