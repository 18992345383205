// ----------------------------------------------------------------------------/
// Anchors
// ----------------------------------------------------------------------------/

a {
	&, &:link, &:visited {
		color: $colour--support;
		outline: none;
		text-decoration: none;
		transition: all 0.3s ease-out 0s;
	}
	&:active, &:focus, &:hover {
		color: $colour--support;
		text-decoration: underline;
	}
}


// END OF DOCUMENT
