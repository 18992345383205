// ----------------------------------------------------------------------------/
// Locations
// ----------------------------------------------------------------------------/

section.locations {
	padding: 0 0 100px;

	> .container {
		border-top: 1px solid $colour--secondary;
		padding: 32px 0 0;
	}

	h5 { margin-bottom: 80px; }

	.locations__item {
		@include span(6);
		margin-bottom: 100px;

		@include breakpoint($media-md) {
			@include span(12);
			padding: 0;
		}

		iframe {
			height: 330px;
			margin-bottom: 26px;
			width: 100%;
		}

		address {
			color: #929292;
			font-style: normal;
			margin-bottom: 36px;

			strong {
				@extend strong;
				color: $colour--secondary;
				display: block;
				margin-bottom: 10px;
				text-transform: uppercase;
			}
		}
	}
}


// END OF DOCUMENT
