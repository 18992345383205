// ----------------------------------------------------------------------------/
// HR divider / seperator
// ----------------------------------------------------------------------------/

hr {
    border: 0;
    border-top: 1px #ddd solid;
    display: block;
    height: 1px;
    margin: 1.5em 0px;
    padding: 0;
}


// END OF DOCUMENT
