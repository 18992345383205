// ----------------------------------------------------------------------------/
// Buttons
// ----------------------------------------------------------------------------/

.btn {
    @extend .cta-text;
    border-radius: 50px;
    display: inline-block;
    outline: none;
    padding: 8px 22px 9px;
    transition: all .3s ease-in-out;

    &, &:active, &:focus, &:hover, &:link, &:visited {
        text-decoration: none;
    }

    // Solid
    &.btn--solid {
        background-color: $colour--primary;
        border: 1px solid $colour--primary;
        color: #fff;
        &:hover {
            background-color: darken($colour--primary, 8%);
            border-color: darken($colour--primary, 8%);
        }
        &:active, &:focus {
            background-color: darken($colour--primary, 16%);
            border-color: darken($colour--primary, 15%);
        }

        &.btn--arrow { background-image: url('../images/buttons/btn-arrow-white--right.svg'); }
        &.arrow--down { background-image: url('../images/buttons/btn-arrow-white--down.svg'); }
    }

    // Outline
    &.btn--outline {
        background-color: #fff;
        border: 1px solid #929292;
        color: #929292;
        padding: 8px 43px 9px;
        &:hover {
            background-color: #F8F8F9;
            border-color: $colour--primary;
            color: $colour--primary;
        }
        &:active, &:focus {
            background-color: darken(#F8F8F9, 4%);
            border-color: darken($colour--primary, 15%);
        }

        &.btn--arrow { background-image: url('../images/buttons/btn-arrow-blue--right.svg'); }
        &.arrow--down { background-image: url('../images/buttons/btn-arrow-blue--down.svg'); }
    }

    // Button arrow
    &.btn--arrow {
        background-position: center right 28px;
        background-repeat: no-repeat;
        background-size: 20px 19px;
        padding: 8px 110px 9px 22px;

        &:not(.arrow--down) {
            &:active, &:focus, &:hover { background-position: center right 18px; }
        }

        &.arrow--down {
            background-position: top 6px right 24px;
        }

        @include breakpoint($media-sm) { padding-right: 70px; }
    }
}


// END OF DOCUMENT
